* {
  font-family: "Open Sans",sans-serif;
}

html {
  width: 100%;
  height: 100%;
}

.web-ht {
  min-height: 80vh;
}

.sidebar-height {
  height: 230px;
}

.sidebar-width {
  min-width: 220px;
}

.table {
  font-size: x-small;
}

.table td {
  text-align: center;
}


.table th {
  text-align: center;
}

.table-pad {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.mb-2 > div:nth-child(1) {
 height: 250px;
 overflow: auto;
}

.pt-2 {
justify-content: center;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
